import request from '@/utils/request'

// 游戏--1宅机弟自孵化账号 2华星严选 3爆款案例 4近期资讯
export function getGameBanner(data) {
  return request({
    url: '/web/game/getGameBanner',
    method: 'get',
    params: data
  })
}
// 游戏--游戏主播招募
export function gameJoin(data) {
  return request({
    url: '/web/game/gameJoin',
    method: 'POST',
    data
  })
}
